import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { hot } from 'react-hot-loader/root';
import Home from './components/main/index.jsx'
import StacherHome from "./components/main/NewHome";
import logo from "assets/logo.png";
import screen from "assets/screens/img.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  renderMobile() {
    return (
      <div
        style={{
          background: "#291720",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "0px",
          color: "white",
        }}
      >
        <img src={logo} style={{width: "48px", height: "48px", position: "absolute", top: "10px", left: "10px"}} />
        <img src={screen} style={{width: "80%"}} />
        <h3 style={{margin: "none", padding: "none"}}>
          Stacher is a Desktop App
        </h3>
        <span>Come Back on your PC/Laptop!</span>
      </div>
    )
  }

  render() {
    return <div>
      {
        this.isMobile() ?
        this.renderMobile() :
        <StacherHome title={"stacher.io"}/>
      }
    </div>
  }
}

export default hot(App);
