import React from 'react';
import PropTypes from 'prop-types';
import style from './stacher.css';
import logo from "../../assets/logo.png";
import pic1 from "../../assets/screens/1.png";
import pic2 from "../../assets/screens/2.png";
import pic3 from "../../assets/screens/3.png";
import pic4 from "../../assets/screens/4.png";
import pic5 from "../../assets/screens/5.png";
import pic6 from "../../assets/screens/6.png";
import pic7 from "../../assets/screens/7.png";
import pic8 from "../../assets/screens/8.png";
import pic9 from "../../assets/screens/9.png";
import pic10 from "../../assets/screens/10.png";
import pic11 from "../../assets/screens/11.png";

import Carousel from 'nuka-carousel';


class Home extends React.Component {

    constructor(props) {
        super();

    }


  componentDidMount() {

  }


  renderNav() {
    return (
        <div className={style.navBar}>
            <img src={logo} />
          <div>
              stacher.io
          </div>
            <a
              className={style.redditButton}
              href="https://reddit.com/r/stacherio" target="_blank">
              {this.redditLogo()}
              Check out the stacher subreddit
            </a>
            <button
                onClick={() => {document.getElementById('downloadSection').scrollIntoView({
                    behavior: 'smooth'
                });}}
                class={style.downloadButton}
            >Download Stacher</button>
            <button className={style.donateButton}
                    onClick={() => window.open("https://donate.stacher.io/")}

            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
            </button>
        </div>
    )
  }

  renderBackground() {
        return (
            <ul className={style.bubbles}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        );
  }

  renderBanner() {
        const slides = [ pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11];
    return (
        <div className={style.banner}>

            <div className={style.soon}>

                <Carousel
                    className={style.slider}
                    autoPlay
                >
                    {slides.map((img) => <div className={style.slide}> <img src={img}/></div> )}
                </Carousel>

            </div>

        </div>
    )
  }

  renderFeatures() {
        return (
            <div className={style.featuresContainer}>
                <h2>More Information</h2>
                        <div className={style.moreInfo}>
                            Stacher is a cross-platform desktop application that serves as a front-end <br/>
                            to the popular <a href="https://youtube-dl.org/" target="_blank">youtube-dl</a> command line tool.  There are a good bit of youtube-dl's features <br/>
                            available in Stacher and there will be more coming through automatic updates.<br/><br/>

                            If you're more familiar with the command line, after pasting a url, you can press COMMAND + ENTER <br/>
                            (or CTRL + ENTER) to open a command line editor to add or edit the command line before beginning the download.
                            <br/>

                          {/*<button className={style.button}>youtube-dl project page</button>*/}
                        </div>
            </div>
        );
  }

  renderDownloads() {
      const downloads = [
          {
              label: "Mac OS",
              download: "https://cdn.stacher.io/builds/Stacher.pkg",
              logo: this.appleLogo,
              enabled: true,
          },
          {
              label: "Windows",
              download: "https://cdn.stacher.io/builds/StacherSetup.exe",
              logo: this.windowsLogo,
              enabled: true,
          },
          {
              label: "Linux",
              download: "",
              logo: this.nixLogo,
              enabled: false,
          },
      ];

      return (
          <div className={style.downloadContainer} id="downloadSection">
              <div>
                  <h2>Downloads</h2>
              </div>
              {downloads.map((download) =>
                  <div className={style.downloadItem}>
                      {download.logo()}
                      <br/>
                      <div className={style.downloadInfo}>
                          <div className={style.downloadHeader}>{download.label}</div>
                          <div className={style.downloadSubText}>Available for 64-Bit Only</div>
                          <button

                              onClick={() => window.open(download.download)}
                              disabled={!download.enabled}>{download.enabled ? 'Download' : 'Coming Soon'}</button>
                      </div>
                  </div>
              )}
          </div>
      )
  }

  renderFooter() {
        return (
            <div className={style.footer}>

            </div>
        )
  }


  appleLogo() {
    return (
        <svg viewBox="0 0 170 170" fill="currentColor" width="140" >
          <path d="M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71.12 1.083.17 2.166.17 3.24z"/>
        </svg>
    )
  }

  windowsLogo() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="140"  viewBox="0 0 256 257" preserveAspectRatio="xMidYMid">
                <path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z"  />
            </svg>
        )
  }

  nixLogo() {
        return (
            <svg
                 xmlns="http://www.w3.org/2000/svg" width="140x" height="140px"
                 viewBox="100 100 225.197 225.197" >

        <path d="M139.043,212.595c0,10.064-8.159,18.225-18.23,18.225c-10.059,0-18.218-8.16-18.218-18.225
		c0-10.06,8.159-18.219,18.218-18.219C130.884,194.376,139.043,202.535,139.043,212.595z M242.717,301.201
		c5.033,8.709,16.173,11.696,24.889,6.67c8.715-5.033,11.701-16.179,6.669-24.895c-5.032-8.715-16.173-11.695-24.888-6.663
		C240.671,281.346,237.685,292.486,242.717,301.201z M274.274,142.219c5.032-8.717,2.052-19.86-6.669-24.887
		c-8.71-5.032-19.855-2.046-24.889,6.667c-5.032,8.715-2.046,19.857,6.67,24.889C258.102,153.92,269.248,150.934,274.274,142.219z
		 M212.602,160.632c27.153,0,49.434,20.814,51.761,47.364l26.372-0.416c-1.252-19.727-9.809-37.469-22.995-50.551
		c-6.98,2.693-15.079,2.327-22.066-1.71c-6.992-4.037-11.359-10.871-12.514-18.275c-6.554-1.78-13.448-2.733-20.558-2.733
		c-12.471,0-24.259,2.916-34.727,8.103l12.832,23.043C197.357,162.367,204.784,160.632,212.602,160.632z M160.633,212.595
		c0-17.577,8.734-33.121,22.097-42.52l-13.54-22.634c-15.684,10.474-27.367,26.451-32.296,45.183
		c5.833,4.697,9.57,11.897,9.57,19.972c0,8.08-3.738,15.28-9.57,19.978c4.929,18.731,16.612,34.708,32.296,45.188l13.54-22.634
		C169.367,245.722,160.633,230.184,160.633,212.595z M212.602,264.568c-7.817,0-15.244-1.734-21.895-4.83l-12.832,23.043
		c10.468,5.191,22.255,8.104,34.727,8.104c7.109,0,14.004-0.946,20.558-2.729c1.154-7.409,5.521-14.243,12.514-18.273
		c6.987-4.037,15.086-4.404,22.066-1.711c13.187-13.088,21.743-30.83,22.995-50.557l-26.372-0.409
		C262.035,243.749,239.755,264.568,212.602,264.568z"/>
        </svg>

        )
  }

  redditLogo() {
      return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="28.098px" height="28.097px" viewBox="0 0 98.098 98.097"
             >
        <g>
          <path d="M98.098,48.141c0-5.988-4.873-10.862-10.861-10.862c-2.84,0-5.474,1.146-7.484,3.087c-7.403-4.9-17.43-8.024-28.53-8.421
			l6.063-19.172l16.414,3.866c-0.004,0.081-0.024,0.156-0.024,0.237c0,4.898,3.983,8.883,8.886,8.883
			c4.896,0,8.877-3.984,8.877-8.883c0-4.896-3.981-8.879-8.877-8.879c-3.761,0-6.965,2.354-8.26,5.658L56.609,9.492
			c-0.77-0.188-1.56,0.259-1.799,1.021L48.047,31.89c-11.607,0.141-22.122,3.281-29.852,8.32c-1.999-1.843-4.604-2.932-7.34-2.932
			C4.869,37.278,0,42.152,0,48.14c0,3.877,2.083,7.419,5.378,9.352c-0.207,1.147-0.346,2.309-0.346,3.49
			C5.032,77.04,24.685,90.1,48.844,90.1c24.16,0,43.814-13.062,43.814-29.118c0-1.113-0.116-2.207-0.301-3.289
			C95.875,55.82,98.098,52.205,98.098,48.141z M82.561,11.036c3.219,0,5.836,2.619,5.836,5.84c0,3.222-2.617,5.843-5.836,5.843
			c-3.223,0-5.847-2.621-5.847-5.843C76.714,13.655,79.338,11.036,82.561,11.036z M3.041,48.141c0-4.312,3.505-7.821,7.814-7.821
			c1.759,0,3.446,0.62,4.816,1.695c-4.542,3.504-7.84,7.729-9.467,12.381C4.25,52.945,3.041,50.643,3.041,48.141z M48.844,87.062
			c-22.481,0-40.771-11.697-40.771-26.078c0-14.378,18.29-26.08,40.771-26.08c22.482,0,40.775,11.701,40.775,26.08
			C89.619,75.363,71.326,87.062,48.844,87.062z M91.574,54.625c-1.576-4.677-4.836-8.929-9.351-12.46
			c1.396-1.174,3.147-1.846,5.011-1.846c4.314,0,7.82,3.51,7.82,7.821C95.056,50.806,93.723,53.197,91.574,54.625z"/>
          <path d="M40.625,55.597c0-3.564-2.898-6.466-6.462-6.466c-3.564,0-6.466,2.899-6.466,6.466c0,3.562,2.901,6.462,6.466,6.462
			C37.727,62.059,40.625,59.16,40.625,55.597z"/>
          <path d="M63.961,49.131c-3.562,0-6.462,2.899-6.462,6.466c0,3.562,2.897,6.462,6.462,6.462c3.562,0,6.461-2.897,6.461-6.462
			C70.422,52.031,67.523,49.131,63.961,49.131z"/>
          <path d="M62.582,72.611c-2.658,2.658-7.067,3.951-13.48,3.951c-0.018,0-0.033,0.01-0.054,0.01c-0.019,0-0.035-0.01-0.054-0.01
			c-6.413,0-10.822-1.293-13.479-3.951c-0.594-0.594-1.557-0.594-2.15,0c-0.594,0.596-0.594,1.557-0.002,2.149
			c3.258,3.259,8.37,4.841,15.631,4.841c0.019,0,0.035-0.011,0.054-0.011c0.021,0,0.036,0.011,0.054,0.011
			c7.259,0,12.373-1.582,15.63-4.841c0.594-0.594,0.594-1.555,0-2.149C64.139,72.017,63.176,72.017,62.582,72.611z"/>
        </g>
        </svg>
      )
  }

  render() {
    return (
        <div>
            <div className={style.mobile}>
                {this.renderBackground()}
                <img src={logo}/>
                <h1>stacher.io</h1>
                <div style={{padding: "50px"}}>Stacher is a desktop application for Mac OS, Windows, and Ubuntu.  Please come back to this
                site on your desktop to download the application!</div>
            </div>
            {this.renderNav()}
            {this.renderBanner()}
            {this.renderBackground()}
            {this.renderFeatures()}
            {this.renderDownloads()}
        </div>
    );
  }
}

Home.propTypes = {
  title: PropTypes.string,
};

export default Home;
